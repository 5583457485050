.aw-wrapper {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
}

.h-wrapper {
    width: 100vw;
    padding-top: 10rem;
    padding-bottom: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .h-wrapper {
        padding-top: calc(1rem * 300);
        padding-bottom: calc(1rem * 100);
    }
}

.floatingNav {
    background: black;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    padding-top: calc(1rem * 10);
}

.h-container {
    width: calc(100% / 1920 * 1600);
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 768px) {
    .h-container {
        flex-direction: column;
        gap: calc(1rem * 20);
    }
}

.h-logo-container {
    width: 40%;
    height: 120rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
}

.h-logo-image {
    width: 250rem;
    height: auto;
    object-fit: contain;
    object-position: center;
}

@media (max-width: 768px) {
    .h-logo-container {
        gap: calc(1rem * 1.5);
        align-items: center;
        width: auto;
        height: auto;
    }

    .h-logo-image {
        width: 1000rem;
    }
}

.h-nav-box-container {
    height: 100%;
    width: calc(1rem * 992);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .h-nav-box-container {
        display: none;
    }
}

.h-nav-box {
    cursor: pointer;
}

.h-nav-box-title {
    font-family: "Montserrat", sans-serif;
    font-size: 25rem;
    font-weight: 700;
    line-height: 30rem;
    text-align: left;
    color: #FFFFFF;
    transition: color 0.3s ease-in-out;
}

.h-nav-box:hover .h-nav-box-title {
    color: #27271d;
}

.h-bn-button {
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    padding: 5rem 20rem;
    gap: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #27271d;
    transition: background-color 0.3s ease-in-out;
}

.h-bn-button-img {
    font-family: "Montserrat", sans-serif;
    font-size: 25rem;
    font-weight: 700;
    line-height: 30rem;
    text-align: left;
    color: #FFFFFF;
    transition: color 0.3s ease-in-out;
}

.h-bn-button:hover {
    background-color: white;
}

.h-bn-button:hover .h-bn-button-img {
    filter: brightness(0);
}

@media (max-width: 768px) {
    .h-bn-button {
        padding: 20rem 80rem;
        margin: 100rem
    }

    .h-bn-button-img {
        font-size: 100rem;
        line-height: 120rem;
    }
}

.w-wrapper {
    width: 100%;
    height: auto;
    aspect-ratio: 1920/841;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: calc(1rem * 67.88);
}

@media (max-width: 768px) {
    .w-wrapper {
        align-items: center;
        aspect-ratio: auto;
    }
}

.w-three-star-wrapper {
    width: calc(1rem * 97);
    height: auto;
    align-self: flex-start;
    aspect-ratio: 97/127;
    margin-left:  calc(1rem * 160);
}

@media (max-width: 768px) {
    .w-three-star-wrapper {
        display: none;
    }
}

.w-content {
    width: calc(1rem * 676);
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: calc(1rem * 20);
    margin-left: calc(1rem * 235);
}

@media (max-width: 768px) {
    .w-content {
        width: 1500rem;
        margin-left: 0;
        align-items: center;
    }
}

.w-yc-text {
    font-family: "ElephantRe", sans-serif;
    font-weight: 700;
    font-size: calc(1rem * 36);
    color: white;
    text-align: left;
    line-height: 1.2;
    width: 100%;
    margin-bottom: calc(1rem * 20);
}

@media (max-width: 768px) {
    .w-yc-text {
        display: none;
    }
}

.w-c-inner {
    width: calc(100% / 576 * 466);
    height: auto;
    gap: calc(1rem * 24);
}

@media (max-width: 768px) {
    .w-c-inner {
        display: none;
    }
}


.w-description {
    width: 100%;
    font-family: "Times New Roman", serif;
    font-weight: 400;
    font-size: calc(1rem * 21);
    text-align: justify;
    color: white;
}

.w-button {
    cursor: pointer;
    width: calc(1rem * 188);
    height: calc(1rem * 43);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #27271d;
    transition: background-color 0.3s ease-in-out;
}

.w-button-img {
    height: calc(1rem * 23);
    transition: filter 0.3s ease-in-out;
}

.w-button:hover {
    background-color: white;
}

.w-button:hover .w-button-img {
    filter: brightness(0) saturate(100%) invert(67%) sepia(84%) saturate(440%) hue-rotate(351deg) brightness(94%) contrast(97%);
}

@media (max-width: 768px) {
    .w-button {
        width: 846rem;
        height: 193.5rem;
        margin-bottom: 250rem;
    }

    .w-button-img {
        height: 103.5rem;
    }
}

.w-down-btn-wrapper {
    align-self: flex-end;
    width: calc(1rem * 120);
    height: auto;
    aspect-ratio: 120/120;
    margin-right: calc(1rem * 120);
    transition: all 0.3s ease-in-out;
}

@media (max-width: 768px) {
    .w-down-btn-wrapper {
        display: none;
    }
}

.w-down-btn-wrapper.floatingBtn {
    position: fixed;
    bottom: calc(1rem * 120);
    right: calc(1rem * 120);
    margin-right: 0;
    z-index: 1000;
}

.w-down-btn {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    transition: all 0.3s ease-in-out;
}

.floatingBtn .w-down-btn {
    border: 2px solid black;
}

.w-down-btn-inner {
    width: calc(1rem * 48);
    height: auto;
    aspect-ratio: 48/48;
    transition: all 0.3s ease-in-out;
    filter: brightness(0) saturate(100%) invert(0%) sepia(4%) saturate(0%) hue-rotate(321deg) brightness(97%) contrast(100%);
}

.w-down-btn:hover{
    transform: scale(1.1);
}

.w-down-btn:hover .w-down-btn-inner {
    transform: scale(1.6);
}

.floatingBtn .w-down-btn:hover .w-down-btn-inner {
    transform: scale(1.6) rotate(180deg);
}

.floatingBtn .w-down-btn-inner{
    transform: rotate(180deg);
}

.mb-nav-btn {
    display: none;
    align-items: flex-start;
    width: 1510rem;
    height: fit-content;
    position: fixed;
    top: 0;
    left: -1510rem;
    transition: left 0.3s ease-in-out;
    z-index: 2000;
}

@media (max-width: 768px) {
    .mb-nav-btn {
        display: flex;
    }
}

.mb-nav-btn.mb-nav-open {
    left: 0;
}

.mb-nav-logo-display-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 1920rem;
    height: 300rem;
    background: black;
    z-index: 500;
    transition: top 0.3s ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.mb-nav-logo-display-container.hidden {
    top: -300rem;
}

.mb-nav-logo-display-container .mb-nav-logo-display {
    width: 500rem;
    height: 240rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 100rem;
}

.mb-nav-logo-display-container .mb-nav-logo-display img {
    width: 100%;
    object-fit: contain;
}

.mb-nav-o-b-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 200rem;
    height: 200rem;
    margin: 50rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    z-index: 1000;
    border: black 2rem solid;
}

.mb-nav-o-b-img {
    width: 100rem;
    height: 100rem;
}

.mb-nav-content {
    display: flex;
    width: 1510rem;
    height: 100vh;
    background: black;
    padding: 80rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 80rem;
}

.mb-nav-list {
    width: 1350rem;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.mb-nav-item {
    margin: 0 80rem;
    width: 1190rem;
    height: 300rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    font-size: calc(1rem * 100);
    font-weight: 700;
    line-height: calc(1rem * 45.14);
    text-align: left;
    color: black;
    gap: 50rem;
}

.mb-nav-i-decor {
    width: 50rem;
    height: 50rem;
}

.mb-nav-bn-button {
    margin-top: 100rem;
    cursor: pointer;
    width: 846rem;
    height: 193.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #27271d;
    transition: background-color 0.3s ease-in-out;
}

.mb-nav-bn-img {
    height: 103.5rem;
    transition: filter 0.3s ease-in-out;
}

.mb-nav-bn-button:hover {
    background-color: white;
}

.mb-nav-bn-button:hover .mb-nav-bn-img {
    filter: brightness(0) saturate(100%) invert(67%) sepia(84%) saturate(440%) hue-rotate(351deg) brightness(94%) contrast(97%);
}

.mb-down-btn-wrapper {
    display: none;
    width: 250rem;
    height: 250rem;
    position: fixed;
    bottom: 120rem;
    right: 120rem;
    z-index: 1000;
}

@media (max-width: 768px) {
    .mb-down-btn-wrapper {
        display: block;
    }
}

.mb-down-btn {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    overflow: hidden;
    border: black 2rem solid;
}

.mb-down-btn-inner {
    width: 100rem;
    height: 100rem;
    transition: all 0.3s ease-in-out;
    filter: brightness(0) saturate(100%) invert(0%) sepia(4%) saturate(0%) hue-rotate(321deg) brightness(97%) contrast(100%);
}

.floatingBtn .mb-down-btn-inner {
    transform: rotate(180deg);
}