body {
  font-size: calc(100vw / 1920);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "ElephantRe";
  src: url("../fonts/ElephantRe.ttf") format("truetype");
}

@font-face {
  font-family: "th-goliad-clean";
  src: url("../fonts/th-goliad-clean.otf") format("opentype");
}

@font-face {
  font-family: "Ballerina Script";
  src: url("../fonts/Ballerina Script.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "regio-mono-bold";
  src: url("../fonts/regio-mono-bold.otf") format("opentype");
}

@font-face {
  font-family: "regio-mono-regular";
  src: url("../fonts/regio-mono-regular.otf") format("opentype");
}

@font-face {
  font-family: "Freestyle Script Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Freestyle Script Regular"),
    url("../fonts/FREESCPT.woff") format("woff");
}
