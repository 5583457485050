.au-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  padding-top: 50rem;
  padding-bottom: 50rem;
  background: rgb(255, 255, 255);
}

@media (max-width: 768px) {
  .au-wrapper {
    padding-top: 200rem;
    padding-bottom: 100rem;
  }
}

.au-wrapper-title {
  font-family: "ElephantRe", sans-serif;
  font-weight: 700;
  font-size: calc(1rem * 48);
  color: black;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.au-wrapper-title-main {
  margin-bottom: calc(1rem * 10);
}

.au-wrapper-title-sub {
  font-size: calc(1rem * 24);
  font-weight: 500;
}

@media (max-width: 768px) {
  .au-wrapper-title {
    font-size: calc(1rem * 100);
    margin-bottom: calc(1rem * 30);
  }

  .au-wrapper-title-sub {
    font-size: calc(1rem * 48);
  }
}

.au-content-wrapper {
  width: 100%;
  height: 800rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .au-content-wrapper {
    height: 2100rem;
  }
}
