.om-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: calc(1rem * 70) 0;
    gap: calc(1rem * 50);
}

.om-title-container {
    width: 1920rem;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: calc(1rem * 30);
    overflow-x: hidden;
}

.om-title-container {
    width: calc(1rem * 1218);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: calc(1rem * 30);
}

@media (max-width: 768px) {
    .om-title-container {
        width: 1522.5rem;
    }
}

.om-title {
    width: calc(100% / 1218 * 453);
    font-family: "ElephantRe", sans-serif;
    font-weight: 700;
    font-size: calc(1rem * 48);
    color: black;
    text-align: center;
    line-height: 1.2;
}

@media (max-width: 768px) {
    .om-title {
        width: 60%;
        font-size: calc(1rem * 100);
    }
}

.om-subtitle {
    width: 100%;
    font-family: "Gowun Batang", serif;
    font-size: calc(1rem * 24);
    text-align: justify;
    color: black;
}

@media (max-width: 768px) {
    .om-subtitle {
        font-size: 60rem;
    }
}

.om-bottom-container {
    width: 100%;
    height: auto;
    aspect-ratio: 1920/510;
    display: flex;
    justify-content: center;
    align-items: center;
}

.om-bottom-content-container {
    width: calc(1rem * 1851);
    height: auto;
    aspect-ratio: 1851/370;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: calc(1rem * 30);
}

@media (max-width: 768px) {
    .om-bottom-content-container {
        flex-direction: column;
    }
}

.om-bottom-content-item-wrapper {
    width: calc(1rem * 597);
    height: auto;
    aspect-ratio: 597/370;
    padding: calc(1rem * 10);
}

.om-bottom-content-item-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.om-bottom-content-item-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
}

.om-bottom-content-item {
    width: calc(1rem * 499);
    height: auto;
    aspect-ratio: 499/303;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.16);
    z-index: 100;
    backdrop-filter: blur(18px);
    border-radius: calc(1rem * 20);
    overflow: hidden;
    flex-direction: column;
    gap: calc(1rem * 30);
}

.om-bottom-content-item-value {
    font-family: 'th-goliad-clean', serif;
    font-size: calc(1rem * 50);
    line-height: calc(1rem * 50);
    color: white;
    transition: all 0.5s ease-in-out;
}

.om-bottom-content-item-container:hover .om-bottom-content-item-value {
    transform: scale(1.3);
    color: #FFD700;
}

.om-bottom-content-item-value:hover {
    transform: scale(1.5);
}

.om-bottom-content-item-name {
    font-family: "regio-mono-regular", serif;
    font-size: calc(1rem * 16);
    line-height: calc(1rem * 16);
    letter-spacing: 2px;
    color: white;
    transition: all 0.5s ease-in-out;
}

.om-bottom-content-item-container:hover .om-bottom-content-item-name {
    transform: scale(1.1);
}

.om-bottom-content-item-icon {
    width: calc(1rem * 100);
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shop-icon {
    width: calc(1rem * 61);
    height: auto;
    aspect-ratio: 61/52;
}

.haircut-icon {
    width: calc(1rem * 48);
    height: auto;
    aspect-ratio: 48/72;
}

.shaves-icon {
    width: calc(1rem * 41);
    height: auto;
    aspect-ratio: 41/74;
}

.om-line {
    width: calc(1rem * 819);
    height: 0;
    border-top: 2px solid #000000;
}

@media (max-width: 768px) {
    .om-bottom-content-item-wrapper {
        width: 1791rem;
        padding: 30rem;
    }

    .om-bottom-content-item {
        width: 1497rem;
        gap: 90rem;
    }

    .om-bottom-content-item-value {
        font-size: 150rem;
        line-height: 150rem;
    }

    .om-bottom-content-item-name {
        font-size: 48rem;
        line-height: 48rem;
    }

    .om-bottom-content-item-icon {
        width: 300rem;
    }

    .shop-icon {
        width: 183rem;
    }

    .haircut-icon {
        width: 144rem;
    }

    .shaves-icon {
        width: 123rem;
    }

    .om-line {
        width: 1638rem;
    }
}