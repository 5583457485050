/* BlogList styles */
.blog-list-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 2rem;
}
.blog-list__title {
  font-family: "ElephantRe", sans-serif;
  font-size: calc(1rem * 48);
  text-align: center;
  margin-bottom: 50rem;
}
.blog-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 80rem;
}

/* BlogCard styles */
.blog-card {
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.blog-card__image {
  width: 100%;
  aspect-ratio: 3 / 2;
  object-fit: cover;
}

.blog-card__content {
  padding: 1.5rem;
}

.blog-card__title {
  font-size: calc(1rem * 30);
  font-weight: bold;
  margin-bottom: 10rem;
  margin-top: 10rem;
}

.blog-card__subtitle {
  font-size: calc(1rem * 24);
  color: #666;
  margin-bottom: 10rem;
}

.blog-card__time {
  font-size: calc(1rem * 24);
  color: #999;
  margin-bottom: 5rem;
}

.blog-card__content {
  font-size: 1rem;
  line-height: 1.5;
}
.blog-card__title, .blog-card__subtitle, .blog-card__time {
  margin-left: 5rem;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 50rem;
}

.pagination button {
  background-color: #f2f2f2;
  border: none;
  color: #333;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0 20rem;
  cursor: pointer;
}

.pagination button.active {
  background-color: #333;
  color: #fff;
}

@media (max-width: 768px) {
  .blog-list-container {
    margin: 20px 50px;
  }
  .blog-list {
    grid-template-columns: 1fr;
  }
  .blog-card__title {
    font-size: calc(1rem * 100);
  }
  .blog-card__subtitle,
  .blog-card__time {
    font-size: calc(1rem * 64);
  }
  .blog-list__title {
    font-size: calc(1rem * 100);
  }
}
