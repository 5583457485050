.bd-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    margin-top: calc(1rem * 70);
    overflow: hidden;
}
.bd-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: auto;
    height: calc(1rem * 500);
    aspect-ratio: 400/500;
    margin-right: 5vw;
    background-color: black;
    transition: all 0.5s ease-in-out;
    position: relative;
}

@media (max-width: 768px) {
    .bd-item {
        height: 1200rem;
    }
}

.bd-item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    position: absolute;
    top: 0;
    left: 0;
}

.focused {
    height: calc(1rem * 680);
    aspect-ratio: 550/680;
}

@media (max-width: 768px) {
    .focused {
        height: 1650rem;
    }
}

.focused:hover .bd-item-detail {
    display: flex;
}

.bd-item-detail {
    display: none;
    align-items: center;
    flex-direction: column;
    width: calc(100% / 11 * 9);
    height: auto;
    aspect-ratio: 450/500;
    border-radius: calc(100% / 550 * 25);
    overflow: clip;
    position: relative;
    box-shadow: 0 4px 5.8px 0 rgba(0, 0, 0, 0.25);
}

.bd-item-detail-bg {
    display: block;
    width: 100%;
    height: 100%;
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(37, 37, 37, 0.17);
    backdrop-filter: blur(18px);
}

.bd-item-name {
    width: calc(100% / 450 * 341);
    font-family: "ElephantRe", sans-serif;
    text-align: center; 
    color: white;
    font-size:  calc(1rem * 50);
    z-index: 100;
    padding-top: calc(100% / 450 * 57);
}

@media (max-width: 768px) {
    .bd-item-name {
        font-size: 171rem;
    }
}

.bd-item-description {
    width: calc(100% / 450 * 341);
    font-family: "Times New Roman", serif;
    font-weight: 100;
    font-style: normal;
    text-align: justify;
    color: white;
    font-size:  calc(1rem * 24);
    z-index: 100;
    padding-top: calc(100% / 450 * 47);
}

@media (max-width: 768px) {
    .bd-item-description {
        font-size: 45rem;
    }
}
