/* Toàn bộ container */
.brotherhood-gallery {
  width: 100%;
  background-color: #000;
  padding: 16px;
  margin: 0 auto;
}

.brotherhood-gallery h2 {
  color: #fff;
  font-family: "ElephantRe", sans-serif;
  text-align: center;
  font-size: calc(1rem * 48);
  font-weight: 700;
  margin-bottom: 32px;
}

/* Lưới ảnh */
.brotherhood-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  /* margin: 10px 150px 150px 100px; */
}

@media (min-width: 200px) {
  .brotherhood-gallery h2 {
    font-size: calc(1rem * 100);
  }
}
@media (min-width: 768px) {
  .brotherhood-grid {
    grid-template-columns: repeat(3, 1fr);
    margin: 20px 80px 80px 100px;
  }
  .brotherhood-gallery h2 {
    font-size: calc(1rem * 48);
  }
}

@media (min-width: 1024px) {
  .brotherhood-grid {
    grid-template-columns: repeat(4, 1fr);
    margin: 10px 150px 150px 150px;
  }
  .brotherhood-gallery h2 {
    font-size: calc(1rem * 48);
  }
}
/* Khung ảnh */
.brotherhood-item {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Ảnh */
.brotherhood-item img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

@media (min-width: 768px) {
  .brotherhood-item img {
    height: 300px;
  }
}

/* Hover hiệu ứng phóng to */
.brotherhood-item:hover img {
  transform: scale(1.1);
}

/* Overlay */
.brotherhood-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  align-items: center;
  padding: 5px;
}

.brotherhood-item:hover .brotherhood-overlay {
  opacity: 1;
}

/* Chữ overlay */
.brotherhood-overlay p {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
}
