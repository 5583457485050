.cu-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: 100%;
    padding: calc(1rem * 80) 0;
    background-color: black;
}

@media (max-width: 768px) {
    .cu-wrapper {
        gap: 200rem;
    }
}

.cu-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(1rem * 1319);
}

@media (max-width: 768px) {
    .cu-container {
        width: calc(1rem * 1500);
    }
}

.cu-title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(1rem * 150);
}

@media (max-width: 768px) {
    .cu-title-wrapper {
        height: 300rem;
        justify-content: center;
        margin-bottom: 125rem;
    }
}

.cu-title {
    width: calc(1rem * 528);
    height: calc(1rem * 48);
}

@media (max-width: 768px) {
    .cu-title{
        width: 1056rem;
        height: 96rem;
    }
}

.cu-title-img {
    width: 100%;
    height: 100%;
}

.cu-content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
}

@media (max-width: 768px) {
    .cu-content-wrapper {
        flex-direction: column;
        align-items: center;
        gap: 200rem;
    }
}

.content-common {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
}

.address {
    width: calc(1rem * 539);
}

@media (max-width: 768px) {
    .address {
        width: 1500rem;
    }
}

.icon {
    width: calc(1rem * 30);
    height: auto;
    aspect-ratio: 1/1;
}

@media (max-width: 768px) {
    .icon {
        width: 120rem;
    }
}

.text {
    height: calc(1rem * 17.28);
    width: auto;
    padding-top: calc(1rem * 10);
}

@media (max-width: 768px) {
    .text {
        height: 69.12rem;
        padding-top: 80rem;
    }
}

.content-item-common {
    margin-top: calc(1rem * 20);
}

@media (max-width: 768px) {
    .content-item-common {
        margin-top: 120rem;
    }
}

.item-text {
    font-size: calc(1rem * 20);
    color: white;
    text-align: justify;
}

@media (max-width: 768px) {
    .item-text {
        font-size: 80rem;
    }
}

.cu-separator {
    width: 66%;
    height: calc(1rem * 27);
    background-color: #ffffff;
    margin-top: calc(1rem * 47);
    margin-bottom: calc(1rem * 47);
}

.cu-copyright {
    height: calc(1rem * 22);
}

@media (max-width: 768px) {
    .cu-copyright {
        height: 55rem;
    }
}