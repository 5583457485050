.oser-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding-top: 50rem;
}

.oser-top-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.oser-top-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .oser-top-container {
        height: fit-content;
        aspect-ratio: auto;
    }

    .oser-top-content-container {
        flex-direction: column;
        height: fit-content;
        aspect-ratio: auto;
        margin: 200rem 0;
        gap: 200rem;
    }
}

.oser-top-left-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: calc(100% / 1920 * 1184.5);
    height: 100%;
}

@media (max-width: 768px) {
    .oser-top-left-container {
        height: 1920rem;
        width: 1920rem;
    }
}


.oser-top-left-decor {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    aspect-ratio: 2369/854;
    z-index: 1;
    background-color: #000000;
}

@media (max-width: 768px) {
    .oser-top-left-decor {
        height: 1500rem;
        aspect-ratio: auto;
    }
}

.oser-top-left-scrollbar {
    width: 100%;
    height: auto;
    aspect-ratio: 2369/129;
    display: flex;
    position: relative;
    justify-content: center;
}

@media (max-width: 768px) {
    .oser-top-left-scrollbar {
        aspect-ratio: 2369/258;
    }
}

.slider-wrapper {
    width: 80%;
    height: 100%;
    z-index: 3;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}

.oser-top-left-services-list {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    height: auto;
    gap: 30rem;
    aspect-ratio: 2369/861;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

@media (max-width: 768px) {
    .oser-top-left-services-list {
        aspect-ratio: auto;
        height: 1720rem;
        gap: 120rem;
    }
}

.item {
    width: 300rem;
    height: 430rem;
    background-color: #E1E1E1;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media (max-width: 768px) {
    .item {
        max-width: 1200rem;
        width: 1200rem;
        height: 1720rem;
    }
}

.item-img {
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.item-content {
    width: 100%;
    height: auto;
    aspect-ratio: 300/100;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: flex-end;
    align-items: flex-end;
}

.item-detail {
    width: 50%;
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20rem;
}

.item-title {
    width: 260rem;
    font-family: regio-mono-regular, serif;
    font-size: 20rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}

.item-description {
    font-family: regio-mono-regular, serif;
    font-size: 20rem;
}

@media (max-width: 768px) {
    .item-detail {
        padding-left: 80rem;
        width: 50%;
    }

    .item-title {
        width: 1040rem;
        font-size: 80rem;
    }

    .item-description {
        font-size: 80rem;
    }
}

.item-bn-button {
    cursor: pointer;
    width: 108rem;
    height: auto;
    aspect-ratio: 108/25;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(237, 174, 39, 1);
    transition: background-color 0.3s ease-in-out;
}

@media (max-width: 768px) {
    .item-bn-button {
        width: 432rem;
    }
}

.item-bn-button-img {
    transition: filter 0.3s ease-in-out;
    width: 85rem;
}

@media (max-width: 768px) {
    .item-bn-button-img {
        width: 340rem;
    }
}

.item-bn-button:hover {
    background-color: white;
}

.item-bn-button:hover .item-bn-button-img {
    filter: brightness(0) saturate(100%) invert(67%) sepia(84%) saturate(440%) hue-rotate(351deg) brightness(94%) contrast(97%);
}

.item-book-now {
    width: calc(50% - 20rem);
    height: fit-content;
}

.item-book-now-img {
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.last-item {
    margin-right: calc(1rem * 61);
}

.first-item {
    margin-left: calc(1rem * 61);
}

@media (max-width: 768px) {
    .item-book-now {
        width: 50%;
    }

    .first-item {
        margin-left: 183rem;
    }

    .last-item {
        margin-right: 183rem;
    }
}

.oser-top-right-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.oser-title {
    font-family: "ElephantRe", sans-serif;
    font-size: calc(1rem * 48);
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
    max-width: 378px;
}

.oser-description {
    width: 1030rem;
    height: 116rem;
    color: black;
    font-size: calc(1rem * 24);
    font-family: "Gowun Batang", serif;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 20rem;
}

.oser-title-decor {
    width: calc(1rem * 80);
    height: auto;
    aspect-ratio: 80/144;
    margin-top: calc(1rem * 10);
    margin-left: calc(1rem * 526);
}

@media (max-width: 768px) {
    .oser-top-right-container {
        height: fit-content;
        width: 1920rem;
        align-items: center;
    }

    .oser-title {
        font-size: calc(1rem * 100);
        max-width: 1200rem;
        margin-left: 0;
    }

    .oser-description {
        width: 1545rem;
        height: 348rem;
        margin-left: 0;
        font-size: 60rem;
    }
    .oser-title-decor {
        width: 160rem;
        margin: 0;
        position: absolute;
        top: -80rem;
        right: 100rem;
    }
}

.oser-mid-container {
    width: 100%;
    height: auto;
    aspect-ratio: 1920/1014.75;
    background: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(1rem * 10);
    padding-top: calc(1rem * 135.25);
}

@media (max-width: 768px) {
    .oser-mid-container {
        height: fit-content;
        aspect-ratio: auto;
        flex-direction: column;
        padding: 200rem 0;
    }
}

.oser-mid-left-container {
    width: calc(1rem * 960);
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
}

@media (max-width: 768px) {
    .oser-mid-left-container {
        width: 100%;
        height: auto;
        aspect-ratio: 960/1014.75;
    }
}

.oser-mid-decor {
    width: 100%;
    height: auto;
    aspect-ratio: 960/881;
    overflow: hidden;
}

.oser-mid-decor-inner {
    width: 100%;
    height: 100%;
    position: relative;
}

.oser-mid-decor-inner-img {
    width: calc(1rem * 1206);
    height: auto;
    aspect-ratio: 696/928;
    object-fit: cover;
    position: absolute;
    top: calc(-1 * 1rem * 246);
    left: 0;
}

@media (max-width: 768px) {
    .oser-mid-decor-inner-img {
        width: 100%;
    }
}

.h-decor-line {
    border-top: 2px solid rgba(0, 0, 0, 1)
}

.v-decor-line {
    border-left: 2px solid rgba(0, 0, 0, 1)
}

.first-line {
    margin-top: calc(1rem * 42);
    width: calc(1rem * 538);
}

.second-line {
    margin-top: calc(1rem * 28.75);
    width: calc(1rem * 300);
}

@media (max-width: 768px) {
    .first-line {
        margin-top: 84rem;
        width: 1076rem;
    }

    .second-line {
        margin-top: 57.5rem;
        width: 600rem;
    }

}

.oser-mid-right-container {
    width: calc(1rem * 960);
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
}

@media (max-width: 768px) {
    .oser-mid-right-container {
        width: 100%;
        height: fit-content;
    }
}

.oser-mid-right-img {
    width: calc(1rem * 672);
    height: auto;
    aspect-ratio: 672/504;
    object-fit: cover;
}

.oser-mid-right-text {
    width: calc(1rem * 672);
    height: auto;
    font-size: calc(1rem * 24);
    font-family: "Gowun Batang", serif;
    font-weight: 400;
    text-align: justify;
    line-height: calc(1rem * 34.75);
    margin-top: calc(1rem * 30);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.oser-mid-right-view-more {
    width: calc(1rem * 207);
    height: auto;
    font-family: "regio-mono-bold", serif;
    font-size: calc(1rem * 32);
    line-height: calc(1rem * 37.19);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

@media (max-width: 768px) {
    .oser-mid-right-img {
        width: 1344rem;
    }

    .oser-mid-right-text {
        width: 1344rem;
        font-size: 48rem;
        line-height: 69.5rem;
        margin-top: 60rem;
    }

    .oser-mid-right-view-more {
        width: 414rem;
        font-size: 64rem;
        line-height: 74.38rem;
    }
}

.oser-mid-right-view-more:hover {
    text-decoration: underline;
}

.third-line {
    position: absolute;
    left: calc(1rem * 105);
    top: calc(1rem * 375.5);
    height: calc(1rem * 304.5);
}

@media (max-width: 768px) {
    .third-line {
        left: 210rem;
        top: 751rem;
        height: 609rem;
    }
}

/* New CSS for Services Display */

.oser-service-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pricing-tab-container {
    width: 82.5%;
    min-height: 100px;
}

.tab-filter {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 45rem;
    margin-block-end: 30rem;
}

.filter-btn {
    color: hsl(0, 0%, 14%);
    font-size: 21rem;
    font-weight: 600;
    text-transform: uppercase;
    min-width: 240rem;
    padding-block: 12rem;
    border: 1px solid hsl(0, 0%, 91%);
    transition: 0.25s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.filter-btn .btn-icon {
    width: 105rem;
    height: 105rem;
}

.filter-btn.active {
    background-color: #555555;
    border-color: hsl(36, 24%, 33%);
    color: white;
}

.grid-list > div.active {
    animation: popup 0.75s ease forwards;
}

@keyframes popup {

    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }

}

.btn-text {
    font-size: 25rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
}

li {
    width: fit-content;
    height: fit-content;
}

.pricing-card {
    background-color: hsla(36, 19%, 38%, 0.1);
    padding: 30rem 37.5rem;
    border-radius: 7.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 22.5rem;
    width: 765rem;
    height: 195rem;
}

.pricing-card .card-banner {
    overflow: hidden;
    align-self: center;
    width: 120rem;
    height: 120rem;
}

.pricing-card .wrapper {
}

.pricing-card .h3 {
    font-size: 30rem;
    margin-block-end: 12rem;
}

.card-text {
    font-size: 24rem;
    margin-block-end: 12rem;
}

.pricing-card .card-price {
    color: hsl(0, 0%, 0%);
    font-family: "Times New Roman", serif;
    font-size: 45rem;
    font-weight: 500;
    justify-self: right;
}

.grid-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 45rem;
    margin-block-end: 45rem;
}

*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.img-cover {
    width: 90%;
    height: 90%;
    object-fit: contain;
    object-position: center;
}

.img-holder {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-des-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    width: calc(100% - 150rem);
}

@media (max-width: 768px) {
    .tab-filter {
        gap: 90rem;
        margin-block-end: 60rem;
    }

    .filter-btn {
        font-size: 42rem;
        min-width: auto;
        width: fit-content;
        padding: 24rem;
        padding-block: 24rem;
    }

    .filter-btn .btn-icon {
        display: none;
    }

    .btn-text {
        font-size: 70rem;
    }

    .pricing-card {
        padding: 60rem 75rem;
        border-radius: 15rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 45rem;
        width: 1530rem;
        height: 390rem;
    }

    .pricing-card .h3 {
        font-size: 60rem;
        margin-block-end: 24rem;
    }

    .pricing-card .card-banner {
        width: 240rem;
        height: 240rem;
    }

    .card-text {
        font-size: 48rem;
        margin-block-end: 24rem;
    }

    .pricing-card .card-price {
        font-size: 90rem;
    }

    .grid-list {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 90rem;
        margin-block-end: 90rem;
    }

    .card-des-wrapper {
        width: calc(100% - 300rem);
    }
}
.price-and-action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 15rem;
  }
  
  .view-more-btn {
    background-color: #333;
    color: white;
    border: none;
    padding: 10rem 20rem;
    border-radius: 5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .view-more-btn:hover {
    background-color: #555;
  }
  
  @media (max-width: 768px) {
    .view-more-btn {
      padding: 20rem 40rem;
      font-size: 40rem;
    }
  }