.os-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: fit-content;
    width: 100%;
}

@media (max-width: 768px) {
    .os-wrapper {
        padding-top: 100rem;
        padding-bottom: 200rem;
    }
}

.os-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(1rem * 240);
}

.os-title {
    font-family: "ElephantRe", sans-serif;
    font-size: calc(1rem * 48);
    color: black;
    text-align: center;
    line-height: 1.2;
    font-weight: 700;
}

@media (max-width: 768px) {
    .os-title-container {
        height: 480rem;
    }

    .os-title {
        font-size: calc(1rem * 100);
    }
}

.os-content-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 84%;
    height: fit-content;
    margin-bottom: calc(1rem * 70);
}

@media (max-width: 768px) {
    .os-content-wrapper {
        flex-direction: column;
        gap: calc(1rem * 20);
    }
}

.os-display {
    width: 1222rem;
    height: 815rem;
    transition: opacity 0.5s ease-in-out;
    background: rgb(128, 128, 128);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    background-size: cover;
}

@media (max-width: 768px) {
    .os-display {
        width: 100%;
        height: auto;
        aspect-ratio: 1222/815;
    }
}

.os-display-text-wrapper {
    width: calc(1126rem - 37.39rem * 2);
    margin-bottom: 40rem;
    padding: 28.61rem 0;
    backdrop-filter: blur(18px);
    box-shadow: 0 4px 6px 0 #00000040;
    background: #2525252B;
    font-family: "Times New Roman", serif;
    font-size: calc(1rem * 24);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20rem;
}

@media (max-width: 768px) {
    .os-display-text-wrapper {
        display: none;
    }
}

.os-display-text-container {
    width: calc(100% - 37.39rem * 2);
    max-height: 400rem;
    height: fit-content;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.os-display-text-container::-webkit-scrollbar {
    display: none;
}

.os-display-text{
    text-align: justify;
    width: 100%;
}

.fade-in {
    opacity: 1;
}

.fade-out {
    opacity: 0;
}

.os-img-slider {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 30%;
    height: 5px;
}

@media (max-width: 768px) {
    .os-img-slider {
        width: 100%;
        height: 483.84rem;
        flex-direction: column;
    }

}

.os-slider {
    display: flex;
    width: calc(1rem * 80);
    height: 100%;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .os-slider {
        height: 80rem;
        width: 100%;
        visibility: hidden;
    }
}

.sb-wrapper {
    width: 5px;
    height: 80%;
}

.os-img-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 815rem;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    gap: 20rem;
}

.os-img-list::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

@media (max-width: 768px) {
    .os-img-list {
        height: 100%;
        width: 100%;
        flex-direction: row;
        gap: 80rem;
    }
}

.os-img-item {
    width: 100%;
    aspect-ratio: 1222/815;
    object-fit: cover;
    background: gray;
}

@media (max-width: 768px) {
    .os-img-item {
        height: 100%;
    }
}