.blog-detail-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
  font-family: Arial, sans-serif;
}

.blog-detail__back-button {
  background: none;
  border: none;
  font-size: 30rem;
  cursor: pointer;
  margin: 20rem 0;
  color: #333;
}

.blog-detail__header {
  margin: 10rem auto;
  text-align: center;
}

.blog-detail__title {
  font-family: "ElephantRe", sans-serif;
  font-size: calc(1rem * 30);
  font-weight: bold;
  margin: 20rem auto;
  color: #222;
}

.blog-detail__subtitle {
  font-family: "Times New Roman", sans-serif;
  text-align: justify;
  font-size: calc(1rem * 24);
  color: #555;
  margin: 20rem auto;
}

.blog-detail__meta {
  font-family: "Times New Roman", sans-serif;
  font-size: calc(1rem * 24);
  margin-bottom: 50rem;
  color: #888;
}

.blog-detail__content {
  font-family: "Times New Roman", sans-serif;
  text-align: justify;
  font-size: calc(1rem * 24);
  line-height: 1.8;
  color: #333;
}

.blog-detail__inline-image {
  display: block;
  margin: 1.5rem auto;
  width: 100%;
  max-width: 500px;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  margin: 30rem auto;
}

@media (max-width: 768px) {
  .blog-detail__back-button {
    font-size: calc(1rem * 100);
    margin: 50rem;
  }
  .blog-detail__title {
    font-size: calc(1rem * 100);
    margin: 80rem;
  }
  .blog-detail__meta {
    font-size: calc(1rem * 64);
  }
  .blog-detail__subtitle, .blog-detail__content {
    font-size: calc(1rem * 64);
    margin: 100rem;
  }

  .blog-detail__inline-image {
    max-width: 80%;
  }
}
