.wh-wrapper {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
}

.wh-main-container {
    width: calc(1rem * 499);
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: calc(1rem * 200);
}

.wh-title-mobile {
    height: calc(1rem * 191.5);
    width: 0;
    font-family: "ElephantRe", sans-serif;
    font-size: 135rem;
    font-weight: 700;
    line-height: 223.125rem;
    visibility: hidden;
}

.wh-content-container {
    width: calc(100% / 300 * 350);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: calc(1rem * 100);
}

.wh-item {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: calc(1rem * 10);
}

.wh-item-day {
    width: 100%;
    font-family: "Times New Roman", serif;
    font-size: calc(1rem * 42);
    color: #000000;
}

.wh-item-time {
    width: 100%;
    font-family: "Times New Roman", serif;
    font-size: calc(1rem * 30);
    color: rgba(147, 147, 147, 1);
}

.wh-content-deco-line {
    margin-top: 200rem;
    display: none;
    width: 100%;
    height: 0;
    border: 1px solid #000000;
}

.wh-decor-container {
    width: calc(1rem * 1421);
    height: auto;
    aspect-ratio: 1421/1043;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    padding-left: calc(1rem * 300);
    padding-top: calc(1rem * 92.5);
}

.wh-decor-img {
    width: calc(1rem * 830);
    height: auto;
    aspect-ratio: 1/1;
    position: relative;
}

.wh-decor-img-shadow {
    width: calc(100% / 830 * 800);
    height: auto;
    aspect-ratio: 1/1;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(220, 220, 220, 1);
}

.wh-decor-img-bg {
    width: calc(100% / 830 * 800);
    height: auto;
    aspect-ratio: 1/1;
    position: absolute;
    top: 0;
    right: 0;
    object-fit: cover;
}

.wh-title-web {
    font-family: "ElephantRe", sans-serif;
    font-size: calc(1rem * 90);
    font-weight: 700;
    line-height: calc(1rem * 148.75);
    text-align: justify;
    writing-mode: vertical-lr;
    margin-left: calc(1rem * 20);
}



@media (max-width: 768px) {

    .wh-title-web {
        display: none;
    }

    .wh-decor-img {
        width: 100%;
    }

    .wh-decor-container {
        padding-left: 0;
        padding-top: 0;
    }

    .wh-content-deco-line {
        display: block;
    }

    .wh-item-time {
        font-size: 64rem;
    }

    .wh-item-day {
        font-size: 75rem;
    }

    .wh-item {
        gap: 30rem;
    }

    .wh-wrapper {
        flex-direction: column-reverse;
        gap: 200rem;
        align-items: center;
    }

    .wh-main-container {
        width: 1497rem;
        padding-left: 0;
    }

    .wh-title-mobile {
        width: 100%;
        height: 383rem;
        visibility: visible;
    }

    .wh-content-container {
        margin-left: 250rem;
        gap: 200rem;
    }
}

